<script lang="ts" setup>
  import type { TypeTextComponent } from '~/types/contentful'

  const props = defineProps<{
    data: TypeTextComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>
  }>()

  const hasTextContent = computed<boolean>(
    () =>
      !!(
        props.data.fields.text?.fields.overline ||
        props.data.fields.text?.fields.display ||
        props.data.fields.text?.fields.displayWithUrl?.fields.title ||
        props.data.fields.text?.fields.heading ||
        props.data.fields.text?.fields.subtitle ||
        props.data.fields.text?.fields.body ||
        props.data.fields.linkButton
      )
  )

  const hasButtons = computed<boolean>(() => !!props.data.fields.primaryButton || !!props.data.fields.secondaryButton)

  const isDarkMode = computed<boolean>(() => props.data.fields.base?.fields.theme === 'Dark')
</script>

<template>
  <section
    :class="[
      'm-text',
      'module-paddings',
      {
        'prop-align-left': props.data.fields.text?.fields.alignment === 'Left',
        'prop-align-center': props.data.fields.text?.fields.alignment === 'Centre',
        'prop-align-right': props.data.fields.text?.fields.alignment === 'Right',
        'buttons-only': !hasTextContent
      }
    ]"
  >
    <CTextBlock
      v-if="hasTextContent"
      :dark-mode="isDarkMode"
      :link-button="data.fields.linkButton"
      :text="data.fields.text"
      class="text-section"
      overflow-wrap="normal"
    />

    <CButtonBlock
      v-if="hasButtons"
      :dark="isDarkMode"
      :primary-button="data.fields.primaryButton"
      :secondary-button="data.fields.secondaryButton"
      class="button-section"
    />
  </section>
</template>

<style lang="scss" scoped>
  .m-text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--gutter);

    background-color: v-bind('data.fields.base?.fields.backgroundColour');

    &.prop-align-left {
      flex-direction: column;

      @include screenMdAndUp {
        flex-direction: row;
      }
    }

    // Note: Center is row on mobile and desktop
    &.prop-align-center {
      flex-direction: row;
    }

    &.prop-align-right {
      flex-direction: column;

      @include screenMdAndUp {
        flex-direction: row-reverse;
      }
    }
  }

  .text-section {
    width: 100%;
    flex-grow: 1;
  }

  .button-section {
    width: 100%;
    flex-grow: 1;
  }

  .m-text.prop-align-left .button-section {
    justify-content: end;
  }

  .m-text.prop-align-center .button-section {
    justify-content: end;
  }

  .m-text.prop-align-right .button-section {
    justify-content: start;
  }

  .m-text.prop-align-left.buttons-only .button-section {
    justify-content: start;
  }

  .m-text.prop-align-center.buttons-only .button-section {
    justify-content: center;
  }

  .m-text.prop-align-right.buttons-only .button-section {
    justify-content: end;
  }
</style>
