<script lang="ts" setup>
  import * as contentful from 'contentful'
  import type { TypeButtonBlockSkeleton, TypeTextBlockSkeleton } from '@/types/contentful'

  const props = defineProps<{
    darkMode?: boolean
    text?: contentful.Entry<TypeTextBlockSkeleton>
  }>()

  function render() {
    const displayTag = props.text?.fields.displayTag?.toString().toLowerCase() || 'h2'

    // If the display is a link, render it as a link
    if (props.text?.fields.displayWithUrl) {
      const displayWithUrl = props.text.fields.displayWithUrl as contentful.Entry<
        TypeButtonBlockSkeleton,
        'WITHOUT_UNRESOLVABLE_LINKS',
        string
      >

      return h(displayTag, {}, [
        h(
          resolveComponent('CLink'),
          {
            class: ['display-link', { 'display-link-dark': props.darkMode }, 'overflow-anywhere'],
            href: displayWithUrl.fields.href,
            isEmbeddedAppLink: displayWithUrl.fields.target === 'App',
            target: displayWithUrl.fields.target === 'Tab' ? '_blank' : undefined
          },
          {
            default: () => displayWithUrl.fields.title
          }
        )
      ])
      // If display is present, render it as a heading
    } else if (props.text?.fields.display) {
      return h(displayTag, { class: 'display-1 overflow-anywhere' }, props.text.fields.display)
    } else {
      return undefined
    }
  }
</script>

<template>
  <render />
</template>

<style lang="scss" scoped>
  // eslint-disable vue-scoped-css/no-unused-selector
  .overflow-anywhere {
    overflow-wrap: anywhere;
  }
</style>
